import React, { Component, useState } from "react";
import styles from "../Styles/Table.module.css";
import MUIDataTable from "mui-datatables";
import {  ThemeProvider, createTheme, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { saveAs } from "file-saver";
import moment from "moment";

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: "none", // Removes shadow
          border:"1px solid #d4d4d4"
        },
      },
    },
  },
});

function Site_report_tbl({data}) {

      const [staus_flag,setStatus_Flag] = useState(null);
      const [hover_flag, setHover_Flag] = useState(false);
      const [anchorEl, setAnchorEl] = useState(null);
      const [file_list, setFile_list] = useState([]);

      const handleView = (row, data, e) => {
        e.stopPropagation();
        setFile_list(row);
        setAnchorEl(e.currentTarget);
        setHover_Flag(true)
        setStatus_Flag(data?.rowData[1])
      };

      
  function handleClose() {
    setAnchorEl(null);
    setHover_Flag(false)
  }

  
    const handleClick = (row,data) => {
    saveAs(row);
    };

    const columns = [
        {
          name: "reports_files",
          label: "reports_files",
          options: {
            filter: false,
            sort: false,
            display: false,
            download: false,
          },
        },
        {
          name: "payment_status",
          label: "payment_status",
          options: {
            filter: false,
            sort: false,
            display: false,
            download: false,
          },
        },
        {
          name: "date_of_collection",
          label: "Date Of Collection",
          options: {
            filter: false,
            sort: true,
            // filterType: 'custom',
            // customFilterListOptions: {
    
            // },
            // filterOptions: {
            //   logic: (age, filters) => {
    
            //   },
            //   display: (filterList, onChange, index, column) => (
            //     <div>
            //       <label id={styles.filter_label} htmlFor="">Collection Date</label>
            //      <DateRangePicker
            //             format="dd MMM yyyy"
            //             ranges={predefinedRanges}
            //             value={!date_change_flag?[]:[selectedFromDate, selectedToDate]}
            //             onChange={handleSelectDateRange}
            //             style={{ width: "100%" }}
            //             size="md"
            //             placement="bottomEnd"
            //             placeholder="Select Collection Date"
            //             preventOverflow
            //           /> 
            //     </div>
            //   ),
            // },
            customBodyRender: (row, data) => {
              if (row == null || row == "") return <></>;
              return (
                <span style={{ textAlign: "center" }}>
                  {moment(row).format("DD MMM YYYY")}
                </span>
              );
            },
          },
        },
        {
          name: "site_name",
          label: "Site Name",
          options: {
            filter: false,
            sort: false,
            display:false,
            customHeadLabelRender: () => {
              return (
                <>  
                    <p style={{ textAlign:"left",marginLeft:"8px" }}>Site Name</p>
                </>
              );
            },
            customBodyRender: (row, data) => {
              
              return (
                <div style={{ textAlign: "left",paddingLeft:"10px" }}>
                <span >
                  {row}
                </span>
                </div>
              );
            },
          },
        },
        {
          name: "description",
          label: "Sample Description",
          options: {
            filter: false,
            sort: false,
          },
        },
        {
          name: "source_of_sample",
          label: "Source",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (row) => {
              return <p style={{ padding: "10px" }}>{row}</p>;
            },
          },
        },
        {
          name: "date_of_report",
          label: "Date Of Report",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data) => {
              if (row == null || row == "") return <></>;
              return (
                <p style={{ textAlign: "center" }}>
                  {moment(row).format("DD MMM YYYY")}
                </p>
              );
            },
          },
        },
        {
          name: "report_sent_date",
          label: "Report Sent Date",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row, data) => {
              if (row == null || row == "") return <></>;
              return (
                <p style={{ textAlign: "center" }}>
                  {moment(row).format("DD MMM YYYY")}
                </p>
              );
            },
          },
        },
        {
          name: "reports_files",
          label: "reports files",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (row,data) => {
      
              return (
                <>
                  <div
                  
                    //  onMouseLeave={handleClose}
                  >
                    <p id={styles.view_btn} onMouseEnter={(e) => handleView(row, data, e)}>View</p>
    
                  </div>
    
            {/* <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            MenuListProps={{ onMouseLeave: handleClose }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {file_list?.map((ele, index) => (
    
        <MenuItem onClick={() => handleClick(ele?.url,data)}>
                <span>file {index + 1}</span>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
                  style={{
                    height: "20px",
                    marginLeft: "10%",
                    cursor: "pointer",
                    // opacity: data?.rowData[1] == 1?"1":"0.3"
                  }}
                  onClick={() => handleClick(ele?.url,data)}
                />
              </MenuItem>
              
            ))}
          </Menu> */}
                <ThemeProvider theme={theme}>
              <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    MenuListProps={{ onMouseLeave: handleClose }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {file_list?.map((ele, index) => (
      
                      <div>
               
                     {staus_flag != 1?  <MenuItem >
                        <span>file {index+1}</span>
          
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
                          style={{
                            height: "20px",
                            marginLeft: "10%",
                            cursor: "pointer",
                            opacity:"0.3"
                          }}
                        />
                      </MenuItem>:
                     <MenuItem onClick={() => handleClick(ele?.url)}>
                     <span>file {index+1}</span>
                     
                     <img
                       src="https://cdn-icons-png.flaticon.com/512/5968/5968377.png"
                       style={{
                         height: "20px",
                         marginLeft: "10%",
                         cursor: "pointer",
                       }}
                       onClick={() => handleClick(ele?.url)}
                     />
                   </MenuItem>
                     
                      }
                       </div>
                    ))}
                  </Menu>
                  </ThemeProvider>
                </>
              );
            },
          },
        },
      ];
    
  const options = {
    filterType: "checkbox",
  };


  return (
    <div>
       <div id="collection_tbl">
      {/* <ThemeProvider theme={theme}> */}
      <MUIDataTable
        title={
          <h4 style={{textAlign:"left",fontSize:"18px"}} id={styles.list_head}>
            Report List
          </h4>
        }
        data={data}
        columns={columns}
        options={{
          options: options,
          selectableRows: "none",
          responsive: "standard",
          viewColumns: false,
          print: false,
          download: false,
          search: false,
          filter: true,
          // pagination: false,
          rowsPerPageOptions: [10, 25, 50, 100],
          tableBodyMaxHeight: "40vh",
          scroll: false,

          sortOrder: {
            name: "date_of_collection",
            direction: "desc",
          },

          textLabels: {
            body: {
              noMatch: "Data Not Available",
            },
          },
         
        }}
      />

    </div>
    </div>
  )
}

export default Site_report_tbl
