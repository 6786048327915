import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import styles from "../Styles/Table.module.css";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import ViewAgendaOutlinedIcon from "@mui/icons-material/ViewAgendaOutlined";
import Edit_Site from "../Modals/Edit_Site";
import MenuAppBar from "../Components/Header";
import { useNavigate } from "react-router-dom";
import Drawer_customer from "../Components/Drawer";
import loader_img from "../Media/Business_chart.gif"
import Swal from "sweetalert2";
import Footer from "../Components/footer";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function Sites({base_url}) {
  const [sites, setSites] = useState([]);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const storage = window.sessionStorage;
  let profile = JSON.parse(storage.getItem("profile"));
  let customer_id = storage.getItem("customer_id");
  let token = storage.getItem("token");
  const navigate = useNavigate();
  let support_details = JSON?.parse(storage.getItem("support_details"));

  const [state_data, setState_Data] = useState([]);
  const [city_data, setCity_Data] = useState([]);
  const [selected_state, setSelected_State] = useState("ALL");
  const [selected_city, setSelected_City] = useState("ALL");

  const [individual_data, setIndividual_Data] = useState([]);

  const [site_load,setSite_Load] = useState(false)

  const [year_heat_map, setYear_Heat_Map] = useState(new Date());
  let info_arr = year_heat_map?.toString()?.split(" ");

  //  material modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const get_sites = () => {
    setSite_Load(true);
    axios.defaults.headers.common = {
      Authorization: "Bearer " + token?.replace('"', ""),
    };
    const formData = new FormData();
    formData.append("customer_id", `${customer_id}`);
    if (selected_state != "ALL") {
      formData.append("state", `${selected_state}`);
    }
    if (selected_city != "ALL") {
      formData.append("city", `${selected_city}`);
    }

    axios
      .post(
        `${base_url}/get-site-list-by-customer`,
        formData,
        {
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "Multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        setSite_Load(false);
        setError(false);
        storage.setItem("site_length", res.data.site.length);
        if(selected_city != "ALL" || selected_state != "ALL"){
          let x =1;
        }else{
          let state_arr=[];
          let state_obj={};
          for(let i=0; i<res.data.site?.length; i++){
            state_obj[res.data?.site[i]?.state]=1;
          }
          for(let x in state_obj){
            state_arr.push(x)
          }
          setState_Data(state_arr)
          setSites(res.data.site);
        }

      })
      .catch((err) => {
        setSite_Load(false);
        
      });
  };


  const handleRow = (rowData) => {
    storage.setItem("site_id", rowData[0]);
    storage.setItem("site_name", rowData[1]);
    navigate("/site_page");
  };

  const get_state = async () => {
    try {
      let res = await fetch("https://reportapi.equinoxlab.com/get_states");
      let data = await res.json();
      if (data.response?.code == "200") {
        setState_Data(data?.Data);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      ;
    }
  };

  const get_city = async (val) => {
    try {
      let res = await fetch("https://reportapi.equinoxlab.com/get_cities", {
        method: "Post",
        headers: { "Content-Type": "Application/json" },
        body: JSON.stringify({
          state: val,
        }),
      });
      let data = await res.json();

      if (data.response?.code == "200") {
        setCity_Data(data?.Data);
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: data?.response?.message,
        });
      }
    } catch (error) {
      ;
    }
  };

  const handleState = (e) => {
    setSelected_City("ALL");
    if (e.target.value == "ALL") {
      setCity_Data([]);
      setSelected_State("ALL");
      setSelected_City("ALL");
    }
    setSelected_State(e.target.value);
    get_city(e.target.value);
  };

  // useEffect(() => {
  //   if (token != "" && token != null) {
  //     get_state();
  //   }
  // }, []);

  useEffect(() => {
    if (token != "" && token != null) {
      if (selected_state != "" && selected_city != "") {
        get_sites();
      }
    }
  }, [selected_state, selected_city]);

  const handleEdit = (data) => {
    setIndividual_Data(data?.rowData);
    handleOpen();
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Site Name",
      options: {
        filter: false,
        sort: false,
        display: false,
        download: false,
      },
    },
    {
      name: "name",
      label: "Site Name",
      options: {
        filter: false,
        sort: false,

        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left",paddingRight:"10px" }}>
              <p id={styles.site_name}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "address",
      label: "Site Address",
      options: {
        filter: false,
        sort: false,


        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left",paddingRight:"10px" }}>
              <p>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: false,      
        customBodyRender:(row)=>{
          return(
            <span style={{paddingRight:"20px"}}>{row}</span>
          )
        }
      },
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort:false,
        customBodyRender:(row)=>{
          return(
            <span style={{paddingRight:"20px"}}>{row}</span>
            
          )
        }
      },

    },
    {
      name: "contact_person_1",
      label: "POC",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(row)=>{
          return(
            <span style={{paddingRight:"20px"}}>{row}</span>
          )
        }
      },
    },
    {
      name: "email_1",
      label: "Email",
      options: {
        filter: false,
        sort: false,
        customBodyRender:(row)=>{
          return(
            <span style={{paddingRight:"20px"}}>{row}</span>
          )
        }
      },
    },
    {
      name: "contact_number_1",
      label: "Mobile",
      options: {
        filter: false,
        sort: false,
        customBodyRender:(row)=>{
          return(
            <span style={{paddingRight:"10px"}}>{row}</span>
          )
        }
      },
    },
    // {
    //   name: "Action",
    //   label: "Action",
    //   options: {
    //     filter: true,
    //     sort: false,
    //    customBodyRender : (row,data) =>{
    //     return(
    //       <ViewAgendaOutlinedIcon  onClick={()=>handleEdit(data)} sx={{cursor:"pointer"}}/>
    //     )
    //    }
    //   },
    // },
  ];

  const options = {
    filterType: "checkbox",
  };
  return (
    <>
      {/* <MenuAppBar/> */}
      <Drawer_customer />
      <div style={{ paddingLeft: "5em", paddingRight: "1em",marginTop:"-2em" }}>
       {site_load?<img id={styles.loader} src={loader_img}/>: <MUIDataTable
          title={
            <h4
              style={{ fontSize: "20px", textAlign: "left" }}
              id={styles.list_head}
            >
              Sites List
            </h4>
          }
          data={sites}
          columns={columns}
          options={{
            options: options,
            selectableRows: "none",
            responsive: "standard",
            viewColumns: false,
            print: false,
            download: false,
            tableBodyMaxHeight: "60vh",
            rowsPerPageOptions: false,
            pagination: true,
            fixedHeader: true,
            filter:false,
            onRowClick: (rowData) => {
              handleRow(rowData);
            },
            setRowProps: () => ({
              style: {
                cursor: "pointer",
              },
            }),
            customToolbar: () => {
              return (
 
                <div style={{display:"inline-flex",gap:"10px"}}>
  {/* <select
                    id={styles.payment_drop}
                    name=""
                    value={selected_state}
                    onChange={handleState}
                  >
                    <option value="ALL">All States</option>
                    {state_data?.map((ele) => (
                      <option value={ele}>{ele}</option>
                    ))}
                  </select>
                  <select
                    id={styles.city_drop}
                    name=""
                    value={selected_city}
                    onChange={(e) => setSelected_City(e.target.value)}
                  >
                    <option value="ALL">Select City</option>
                    {city_data?.map((ele) => (
                      <option value={ele.City}>{ele.City}</option>
                    ))}
                  </select> */}

                         <FormControl size="small" fullWidth>
                          
                           <Select
                             labelId="demo-simple-select-label"
                             id="demo-simple-select"
                             style={{
                               width: "160px",
                               height: "36px",
                               fontSize: "14px",
                               textAlign: "left",
                               backgroundColor:"white",
                               borderRadius: "5px",
                             }}
                             value={selected_state}
                             onChange={handleState}
                           >
                               <MenuItem value="ALL" >All States</MenuItem>
                               {state_data?.map((ele) => (
                      <MenuItem value={ele}>{ele}</MenuItem>
                    ))}
                           </Select>
                         </FormControl>
   

                         <FormControl size="small" fullWidth>
                          
                           <Select
                             labelId="demo-simple-select-label"
                             id="demo-simple-select"
                             style={{
                               width: "160px",
                               height: "36px",
                               fontSize: "14px",
                               textAlign: "left",
                               backgroundColor:"white",
                               borderRadius: "5px",
                             }}
                             value={selected_city}
                             onChange={(e) => setSelected_City(e.target.value)}
                           >
                               <MenuItem value="ALL">Select City</MenuItem>
                               {city_data?.map((ele) => (
                      <MenuItem value={ele.City}>{ele.City}</MenuItem>
                    ))}
                           </Select>
                         </FormControl>
    
                </div>
                

              );
            },
            textLabels: {
              body: {
                noMatch: "Data Not Available",
              },
            },
          }}
        />}
      </div>

      {/* <div id={styles.footer_container}>
              <div className="row" id={styles.footerCopyright}>
                <div className="col-4">
            
                    <p id={styles.copyright}>
              

                      Copyright © {info_arr[3]} Equinox Labs Private Limited
                    </p>
          
                </div>
                <div className="col-8" id={styles.support_contain}>
                    <div>
                       <p><span>KAE Name</span>: {support_details?.planner_name}</p>
                       <p><span>Mobile</span>: {support_details?.planner_number}</p>
                       <p><span>Email</span>: {support_details?.planner_email}</p>
                    </div>
                    <div>
                       <p><span>Sales Person Name</span>: {support_details?.salesperson_name}</p>
                       <p><span>Mobile</span>: {support_details?.salesperson_number}</p>
                       <p><span>Email</span>: {support_details?.salesperson_email}</p>
                    </div>
                </div>
              </div>

          </div> */}
          <Footer/>
      <Edit_Site
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        individual_data={individual_data}
      />
    </>
  );
}

export default Sites;
