import React from 'react'
import Drawer_customer from '../Components/Drawer'
import Audit_Dashboard from './Audit_Dashboard'
import Schedules_List from './Schedules_List'
import Audit_Section_Dashboard from './Audit_Section_Dashboard'

function Compass_dashboard({base_url}) {
  return (
    <>
     <Schedules_List base_url={base_url}
      compass_flag={true}
      />
     <Audit_Dashboard  base_url={base_url} 
     compass_flag={true} 
     />
     <Audit_Section_Dashboard base_url={base_url}
      compass_flag={true}  
      />
    </>
  )
}

export default Compass_dashboard
